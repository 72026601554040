/* eslint-disable react/jsx-no-useless-fragment */
import { useRef } from 'react';
import cx from 'classnames';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { useIntersectionObserver } from 'usehooks-ts';
import { DynamicImage } from './dynamic-grid-image';
import { Link } from '@dx-ui/osc-link';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import type { TDynamicGridItem } from './dynamic-grid-item';
import { useRect } from '@dx-ui/utilities-use-rect';
import { HeadingLevel } from '@dx-ui/osc-heading-level';
import {
  type BaseImageMetrics,
  ANALYTICS_GLOBAL_CLICK_EVENT,
  ImageFunction,
  trackEvent,
} from '@dx-ui/config-metrics';
import { generateDynamicGridMetrics } from './utils/dynamic-grid-analytics';

type TDynamicGridItemWithoutListeners = Omit<TDynamicGridItem, 'onItemClick' | 'onClickLink'>;

export type TDynamicGridItemWOM = TDynamicGridItemWithoutListeners & {
  /** fade-in text animations */
  isAnimated?: boolean;
  /** theme for the component `dark` or `light` */
  brandComponentTheme?: CmsBrandComponentTheme;
  metrics?: Partial<BaseImageMetrics>;
};

export type TDynamicGridItemAndAspectRatiosWOM = TDynamicGridItemWOM & {
  /** The aspect ratio for the grid item images */
  imageAspectRatio: AspectRatio;
  tabletImageAspectRatio?: AspectRatio;
  mobileImageAspectRatio?: AspectRatio;
};

export const DocsTDynamicGridItemWOM: React.FC<
  React.PropsWithChildren<TDynamicGridItemAndAspectRatiosWOM>
> = () => null;

const DynamicGridItemWOM: React.FC<TDynamicGridItemAndAspectRatiosWOM> = (item) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const gridItemRef = useRef<React.ElementRef<'div'>>(null);

  const {
    id,
    imageUrl,
    tabletImageUrl,
    mobileImageUrl,
    imageAltTxt,
    headline,
    link,
    captionData,
    brandComponentTheme,
    shortDescription,
    isAnimated,
    count,
    listHeadline,
    position,
    metrics,
  } = item;

  const aspectRatio = item?.imageAspectRatio;
  const tabletAspectRatio = item?.tabletImageAspectRatio;
  const mobileAspectRatio = item?.mobileImageAspectRatio;
  const { ref: intersectionRef } = useIntersectionObserver({
    threshold: [1],
    freezeOnceVisible: true,
  });
  const observedWrapper = !getIsReducedMotion() && isAnimated ? intersectionRef : null;
  const headlineId = `headline${id}`;
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  const onClick = () => {
    trackEvent(
      ANALYTICS_GLOBAL_CLICK_EVENT,
      generateDynamicGridMetrics({
        position: position || 0,
        count,
        itemTitle: headline,
        headline: listHeadline,
        imageFunction: ImageFunction.Link,
        metrics,
      })
    );
  };

  const handleLinkFocus = () => {
    // Scroll to the element when focused
    // The setTimeout is needed for this to work in Safari browser
    setTimeout(() => {
      gridItemRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }, 10);
  };

  return (
    <>
      {item ? (
        <div
          className={cx(
            'snap group relative flex h-full snap-start flex-col justify-between pb-5',
            item.cmsTranslationClasses
          )}
          data-testid={`dynamicgrid-wom-item-${id}`}
          key={id}
          ref={gridItemRef}
        >
          <div className="flex flex-col">
            {headline && (
              <HeadingLevel
                headingLevelFallback={3}
                id={headlineId}
                data-testid={`dynamicgrid-wom-item-head-${id}`}
                data-osc-product="dynamic-grid-wom-item-headline"
                className={cx('dynamic-grid-item-wom-header my-2', {
                  '!text-text-inverse': isDark,
                  'dynamic-grid-item-wom-header-light': isLight,
                  'dynamic-grid-item-wom-header-no-desc': !shortDescription,
                })}
              >
                {headline}
              </HeadingLevel>
            )}

            <div ref={ref} className="order-first">
              {imageUrl && (
                <DynamicImage
                  id={id}
                  imageUrl={imageUrl}
                  altText={imageAltTxt}
                  aspectRatio={aspectRatio}
                  tabletAspectRatio={tabletAspectRatio}
                  mobileAspectRatio={mobileAspectRatio}
                  tabletImageUrl={tabletImageUrl}
                  mobileImageUrl={mobileImageUrl}
                  width={rect?.width ?? 0}
                  captionData={captionData}
                  className="brand-gu:group-hover:scale-110 brand-gu:group-hover:opacity-80 image-corner-radius duration-300 ease-in-out"
                />
              )}
            </div>
            <div className="mb-2" ref={observedWrapper}>
              <div className="relative z-10 pb-4">
                <BrandTextBody
                  brandComponentTheme={brandComponentTheme}
                  className="!py-0 sm:!text-base lg:!text-lg"
                >
                  {shortDescription || ''}
                </BrandTextBody>
              </div>
            </div>
          </div>

          {link?.label && link?.url ? (
            <Link
              className="dynamic-grid-item-wom-link-text"
              anchorClassName={cx(
                "inline-block decoration-2 after:absolute after:inset-0 after:z-0 after:content-[''] hover:decoration-4 focus:decoration-4",
                'dynamic-grid-item-wom-link-anchor',
                link.anchorClassName,
                {
                  '!text-text-inverse hover:!text-text-inverse': isDark,
                  'dynamic-grid-item-wom-link-anchor-light': isLight,
                  'dynamic-grid-item-wom-link-anchor-default': !isLight && !isDark,
                }
              )}
              isNewWindow={link?.isNewWindow || false}
              url={link?.url}
              showNewWindowIcon={link?.isNewWindow || false}
              underline={false}
              data-testid={`dynamicgrid-wom-item-link-${id}`}
              cidParams={link?.cidParams}
              data-conductrics-goal={link.experimentationConfiguration?.goal}
              data-conductrics-value={link.experimentationConfiguration?.value}
              onFocus={handleLinkFocus}
              onClick={(e) => {
                if (link?.onClick) {
                  link.onClick?.(e);
                }
                onClick();
              }}
            >
              <div className="relative inline">
                {link.label}
                <div
                  className={cx(
                    'absolute h-px w-full origin-bottom-left scale-x-100 duration-[250ms] ease-out group-hover:scale-x-0 group-hover:ease-out',
                    'dynamic-grid-item-wom-link-label',
                    {
                      'dynamic-grid-item-wom-link-label-dark': isDark,
                    }
                  )}
                />
              </div>
            </Link>
          ) : null}
          {item.cmsDocumentControl}
        </div>
      ) : null}
    </>
  );
};

export { DynamicGridItemWOM };
export default DynamicGridItemWOM;
