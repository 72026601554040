import { useRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRect } from '@dx-ui/utilities-use-rect';
import { DynamicImage } from './dynamic-grid-image';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import type { CaptionProps } from '@dx-ui/osc-caption';
import type { Link } from '@dx-ui/osc-link';
import type { VariantTypes } from './dynamic-grid';
import {
  trackEvent,
  ANALYTICS_GLOBAL_CLICK_EVENT,
  type BaseImageMetrics,
} from '@dx-ui/config-metrics';
import { generateDynamicGridMetrics } from './utils/dynamic-grid-analytics';

export type TDynamicGridItem = {
  /** Dynamic Grid item id */
  id: string;
  /** Image for Dynamic Grid Item */
  imageUrl: string;
  tabletImageUrl?: string;
  mobileImageUrl?: string;
  /** Alt Text for Dynamic Grid Item Image */
  imageAltTxt: string;
  /** Image for Displaying in Modal */
  modalImageUrl?: string;
  /** Alt text for Modal Image */
  modalImageAltText?: string;
  /** Text overlaid on Dynamic Grid Item */
  itemTitle?: string;
  /** Headline for Dynamic Grid Item Modal/dialog */
  headline?: string;
  /** Content such as Markdown, or text in Modal/dialog */
  shortDescription?: JSX.Element | string;
  /** Link following the item headline & content in Modal/dialog */
  link?: React.ComponentProps<typeof Link> & {
    experimentationConfiguration?: CmsExperimentationConfiguration;
  };
  /** Callback handler for grid item click event  */
  onItemClick?: (id: string, el: HTMLButtonElement | null) => void;
  /** Callback handler for link click event  */
  onClickLink?: (id?: string) => void;
  /** Add Tailwind classes to root element */
  className?: string;
  /** Caption link and text */
  captionData?: CaptionProps;
  /** List of Audience Ids */
  segmentIds: string[];
  /** CMS document editor button **/
  cmsDocumentControl?: React.ReactNode;
  /* Translation CSS classes to add to each item */
  cmsTranslationClasses?: string;
  /* CPM controlled brand color themes */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** New card variant added for Enterprise refresh */
  variant?: VariantTypes;
  /** Headline for Dynamic Grid */
  listHeadline?: string;
  /** Position of the Dynamic Grid Item */
  position?: number;
  /** Number of Dynamic Grid Items */
  count?: number;
  /** Metrics object for analytics */
  metrics?: Partial<BaseImageMetrics>;
};

export type TDynamicGridItemAndAspectRatios = TDynamicGridItem & {
  /** The aspect ratio for the grid item images */
  imageAspectRatio: AspectRatio;
  tabletImageAspectRatio?: AspectRatio;
  mobileImageAspectRatio?: AspectRatio;
};

export const DocsTDynamicGridItem: React.FC<
  React.PropsWithChildren<TDynamicGridItemAndAspectRatios>
> = () => null;

const DynamicGridItem: React.FC<TDynamicGridItemAndAspectRatios> = (item) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const rect = useRect({ ref });
  const [t] = useTranslation('osc-dynamic-grids');

  const {
    id,
    captionData,
    itemTitle,
    imageUrl,
    tabletImageUrl,
    mobileImageUrl,
    onItemClick,
    className,
    variant,
    brandComponentTheme,
    position,
    count,
    listHeadline,
    headline,
    metrics,
  } = item;
  const aspectRatio = item?.imageAspectRatio;
  const tabletAspectRatio = item?.tabletImageAspectRatio;
  const mobileAspectRatio = item?.mobileImageAspectRatio;
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  const isCardLayout = variant === 'card';

  const onClickGridItem = () => {
    trackEvent(
      ANALYTICS_GLOBAL_CLICK_EVENT,
      generateDynamicGridMetrics({
        metrics,
        position: position || 0,
        count,
        itemTitle,
        headline: listHeadline || headline,
      })
    );
    onItemClick?.(id, buttonRef.current);
  };
  // TODO: NHCBP-3494 - Tailwind utitilies for grid-* classes (see grid.css)
  // TODO: NHCBP-3494 - Tailwind focus:ring utility not working - classNames on "button":
  // focus:ring-2 focus:ring-primary focus:ring-opacity-50

  const handleOnFocus = () => {
    // Scroll to the element when focused
    // The setTimeout is needed for this to work in Safari browser
    setTimeout(() => {
      buttonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }, 10);
  };

  if (item) {
    return (
      <button
        ref={buttonRef}
        type="button"
        data-testid={`dynamicgrid-item-${id}`}
        id={id}
        onClick={onClickGridItem}
        className={cx(
          'group overflow-y-hidden sm:w-auto',
          'focus:ring-primary/50 focus:ring-2',
          'snap snap-start',
          'relative',
          item.cmsTranslationClasses,
          className
        )}
        onFocus={handleOnFocus}
      >
        <div
          className="bg-bg-alt brand-hi-refresh:rounded-lg relative overflow-hidden"
          data-testid={`layout-${isCardLayout ? 'card' : 'default'}`}
        >
          {isCardLayout ? (
            <div className="bg-bg-inverse absolute inset-0 z-10 opacity-0 transition-opacity duration-300 group-hover:opacity-15 group-focus:opacity-15" />
          ) : null}
          <div className={cx('absolute z-10 flex size-full items-end')}>
            <div
              className={cx('from-bg-inverse size-full to-transparent', {
                'bg-gradient-to-t': !isCardLayout,
              })}
            >
              <div
                className={cx('flex size-full items-end duration-200 ease-in-out', {
                  'group-hover:-translate-y-1/3': !isCardLayout,
                })}
              >
                {!isCardLayout && (
                  <div
                    className={cx(
                      'dynamic-grid-item-header mx-auto w-full px-3 leading-tight sm:py-4 lg:py-6 xl:text-3xl'
                    )}
                    data-testid="dynamicgrid-item-btnText"
                  >
                    {itemTitle}
                  </div>
                )}
                <span className="sr-only">{t('openModal')}</span>
              </div>
            </div>
          </div>
          <div ref={ref} className="relative">
            <DynamicImage
              id={id}
              imageUrl={imageUrl}
              altText=""
              aspectRatio={aspectRatio}
              tabletAspectRatio={tabletAspectRatio}
              mobileAspectRatio={mobileAspectRatio}
              tabletImageUrl={tabletImageUrl}
              mobileImageUrl={mobileImageUrl}
              width={rect?.width ?? 0}
              captionData={captionData}
              className="brand-gu:group-hover:scale-110 brand-gu:group-hover:opacity-80 duration-300 ease-in-out"
            />
            {isCardLayout && aspectRatio === '3:4' ? (
              <DynamicGridCardHeader
                ar={aspectRatio}
                isDark={isDark}
                isLight={isLight}
                itemTitle={itemTitle}
              />
            ) : null}
          </div>
          {isCardLayout && aspectRatio !== '3:4' ? (
            <DynamicGridCardHeader
              ar={aspectRatio}
              isDark={isDark}
              isLight={isLight}
              itemTitle={itemTitle}
            />
          ) : null}
        </div>
        {item.cmsDocumentControl}
      </button>
    );
  }

  return null;
};

type DynamicGridCardHeaderProps = {
  ar: AspectRatio;
  isDark: boolean;
  isLight: boolean;
  itemTitle: string | undefined;
};

const DynamicGridCardHeader = ({ ar, isDark, isLight, itemTitle }: DynamicGridCardHeaderProps) => {
  const baseClasses =
    'mx-auto w-full px-3 py-0 min-h-20 flex items-center justify-center xl:text-xl leading-tight dynamic-grid-item-header';
  const positionClasses = ar === '3:4' ? 'absolute inset-x-0 bottom-0' : '';

  return (
    <div
      className={cx(baseClasses, positionClasses, {
        'dynamic-grid-item-header-light': isLight,
        'dynamic-grid-item-header-dark': isDark,
        'bg-primary': !isDark && !isLight,
      })}
      data-testid="dynamicgrid-item-btnText"
    >
      <span className="relative z-10">{itemTitle}</span>
    </div>
  );
};

export { DynamicGridItem };
export default DynamicGridItem;
